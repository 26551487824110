@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "../../../styles/variables";

.swiper {
  overflow: visible;
  bottom: 1.625rem; // 26px
}

.slide {
  background-color: $white;
  width: calc(100vw - 4rem); // 64px
  border-radius: 0.5rem;
}

.slideContent {
  border-radius: 0.5rem;
  overflow: hidden;
}

.shadow {
  pointer-events: none;
  border-radius: 0.5rem;
  position: absolute;
  width: 100%;
  height: 100%;
}

.bottomShadow {
  box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.2);
}

.rightShadow {
  box-shadow: 0px 9px 46px rgba(0, 0, 0, 0.12);
}

.leftShadow {
  box-shadow: 0px -24px 38px rgba(0, 0, 0, 0.14);
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.imageContainer {
  height: 7.875rem; // 126px
  overflow: hidden;
}

.image {
  margin-top: -2.1875rem; // -35px
}

.listViewButton {
  position: absolute;
  height: 2.375rem; // 38px
  top: -5rem; // -80px
  left: 1.875rem; // 30px
  background-color: $white !important;
  line-height: 1rem !important;
  filter: drop-shadow(0px 1.5rem 2.375rem rgba(0, 0, 0, 0.14))
    drop-shadow(0px 0.5625rem 2.875rem rgba(0, 0, 0, 0.12))
    drop-shadow(0px 0.6875rem 0.9375rem rgba(0, 0, 0, 0.2));
}
