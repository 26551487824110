@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.container {
  height: 28.125rem; // 450px
  overflow-y: auto;
  overflow-x: hidden;

  > div {
    border-bottom: 1px solid $border-color-translucent;
  }

  > :last-child {
    border-bottom: none;
  }
}
