@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "../../../styles/variables";

.container {
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
}

.mapContainer {
  position: fixed;
  top: 4.25rem; // 68px
}

.mapShadow {
  position: absolute;
  z-index: 5;
  pointer-events: none;
  height: 100%;
  width: 100%;
  box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.2);
}

.footer {
  height: 6.875rem; // 110px
  display: flex;
  align-items: flex-start;
}

.detailsSlider {
  padding-top: 1.25rem; // 20px
  z-index: $zindex-modal;
  max-height: calc(100vh - 0.9375rem); // 15px
  display: flex;
}

.detailsSlide {
  background-color: $white;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px -0.4375rem 1.0625rem rgba(0, 0, 0, 0.09);
}

.dragIcon {
  width: 4.8125rem; // 77px
  height: 0.1875rem; // 3px
  background-color: $brand-mid-grey;
  margin-top: 0.8125rem; // 13px
}

@media screen and (max-width: 568px) {
  .container {
    min-height: calc(100vh + 0.625rem); // 10px
  }
}
