@import "../../../styles/variables";

.pickupPin {
  height: 1.875rem; // 30px
  width: 1.5625rem; // 25px
  cursor: default;
  color: $brand-mid-grey;
}

.interactivePickupPin {
  cursor: pointer;
}
