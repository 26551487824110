@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "../../../styles/variables.scss";

.card {
  padding: 1rem;
  width: 100%;
}

.card:global(.bg-success) {
  background: $green-008 !important;
  border: 2px solid $green;
  box-shadow: 0px 0px 0px 4px $green-025;
  position: relative;
}

@include media-breakpoint-up(lg) {
  .card {
    width: 12.5rem; // 200px
  }
}
