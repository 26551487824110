@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.containerItem {
  .topContainerContent {
    margin-right: 0.25rem; // 4px
    display: flex;
    flex-direction: row;
    align-items: center;

    .topContentLogo {
      margin-right: 0.125rem; // 2px
    }
    .topContentText {
      font-size: 1rem;
      font-weight: $font-weight-semibold;
    }
  }
}
