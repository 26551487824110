@import "../../../styles/variables.scss";

.boxContainer {
  display: inline-block;
}

.messageContainer {
  padding: 0.75rem 1rem;
  font: bold 1rem Roboto;
  background-color: $white;
  border-bottom: 0.25rem solid $red;
}

.messagePrefix {
  color: $red;
}
