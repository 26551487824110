.App {
  height: 100%;
  min-height: 100vh;
}

@media screen and (max-width: 568px) {
  .App {
    min-height: calc(100vh + 10px);
  }
}
