@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../../styles/variables.scss";

.header {
  color: $brand-black;
  border-bottom: 1px solid $grayscale-80;

  h5 {
    line-height: 1.5rem;
  }

  svg {
    margin-top: 0.1875rem; // 3px
  }
}

@include media-breakpoint-up(lg) {
  .container {
    max-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .content {
    height: 100%;
    overflow-y: auto;
  }
}

@include media-breakpoint-down(lg) {
  .backButton:hover {
    color: $brand-black !important;
  }
}
