@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../../styles/variables.scss";

.container {
  .card {
    width: 100%;
  }

  .dataHeader {
    margin-bottom: 0;
    font-size: 0.875rem; // 14px
    line-height: 1.5rem;
    color: $brand-black;
  }

  .text {
    font-size: 0.875rem; // 14px
    color: $brand-mid-grey;
    line-height: 1.5rem;
  }

  .cardTitle {
    font-size: 1rem;
  }
}

@include media-breakpoint-up(lg) {
  .container {
    max-width: calc(100% - 12.5rem); // 200px

    .card {
      height: 100%;
    }
  }
}
