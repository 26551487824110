@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.container {
  max-width: 82.5rem; // 1320px
}

.listContainer {
  min-width: 24.375rem; // 390px
}

.filterButtonsContainer {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 4; // more than active markers
}
