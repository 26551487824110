@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../styles/variables.scss";

@include media-breakpoint-up(lg) {
  .container {
    min-height: 100vh;
  }
}
