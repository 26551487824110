@import "../../../styles/variables";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .line {
    flex: 1;
    height: 1px;
  }

  .text {
    text-align: center;
    padding-left: 0.5rem; // 8px
    padding-right: 0.5rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.1875rem; // 19px
  }
}
