@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.container {
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}

@media screen and (max-width: 568px) {
  .container {
    min-height: calc(100vh + 0.625rem); // 0.625rem = 10px
  }
}

.container::before {
  position: fixed;
  height: 100%;
  width: 100%;
  content: "";
  background-image: url("../../../assets/icons/BackgroundMobile.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  z-index: -1;
}

.logo {
  width: 5.125rem; // 82px
  height: 2.125rem; // 34px
}

.header {
  padding: 1.125rem 1.5rem; // 18px 24px;
  width: 100%;
}

.loginContainer {
  max-width: 33.5rem; // 536px;
}

@media only screen and (orientation: landscape) {
  .container::before {
    background-repeat: repeat-x;
  }
}

@include media-breakpoint-up(lg) {
  .header {
    position: absolute;
    padding: 0 2.5rem; // 0 40px;
    top: 0;
    box-shadow: none;
    margin-bottom: 0;
    margin-top: 1.125rem; // 18px;
    width: auto;
  }

  .logo {
    width: 5.6875rem; // 91px;
    height: 2.4375rem; // 39px;
    margin: 0;
  }

  .container::before {
    display: none;
  }

  .imageOffset {
    margin-right: -9.0625rem; // -145px;
    max-height: 100vh;
  }

  .content {
    height: 100vh;
    padding-left: 7.5rem; // 120px; // added to avoid content overlapping background image
    display: flex;
    align-items: center;
    overflow-y: auto;
  }

  // 1320px is a min-width when content is no longer overlapping background image
  @media (min-width: 1320px) {
    .content {
      padding-left: 0;
    }
  }

  @media (max-width: 1320px) {
    .imageContainer {
      max-width: calc(100% - 46.875rem) !important; // 46.875rem = 750px
    }
  }
}
