@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../styles/variables.scss";

.linkButton {
  &:hover {
    color: $brand-black !important;
  }
}

@include media-breakpoint-down(sm) {
  .container {
    height: calc(100vh - 9.9375rem); // 159px
    position: relative;
  }

  .backButton {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
