@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "../../../styles/variables";

.container {
  background-color: $white;
}

.mapShadow {
  position: absolute;
  z-index: 5;
  pointer-events: none;
  height: 100%;
  width: 100%;
  box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.2);
}

.list {
  overflow-y: scroll;
  height: calc(100vh - 25.3125rem); // 405px, remove height of header and footer
}

.listWithNextHidden {
  height: calc(100vh - 16.25rem); // 260px, remove height of header
}

.footer {
  height: 9.0625rem; // 145px
  display: flex;
  align-items: flex-start;
  box-shadow: 0 -0.375rem 1.25rem 0 rgba(0, 0, 0, 0.25);
}
