@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.container {
  min-height: calc(100vh / 1.4);
  display: flex;
  flex-direction: column;
}

@include media-breakpoint-up(md) {
  @media (max-height: 800px) {
    .container {
      min-height: calc(100vh / 1.6);
    }
  }
}
