@import "../../../styles/variables.scss";

.navItem {
  width: 100%;

  .navLink {
    padding: 1.5rem; // 24px
    display: flex;
    color: #1a1a1a;
    text-decoration: none;
  }

  :global {
    .active {
      background-color: $grayscale-95;
      box-shadow: inset -4px 0px 0px 0px $brand-red;
      color: $brand-red;
    }
  }
}

.navItem {
  border-bottom: 1px solid $brand-light-grey;
}

.navLink {
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.labelContainer {
  flex-direction: row;
  width: auto;

  .icon {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
    color: $brand-red;
  }

  span {
    font-size: 1rem;
  }
}

.arrow {
  transform: rotate(180deg);
  min-width: 0.5rem;
  width: 0.5rem;
  height: 0.75rem;
}

.subTitle {
  font-size: 0.875rem; // 14px
  margin-top: 0.5rem;
}

.margin40 {
  margin-left: 2.5rem; // 40px
}

.badge {
  text-overflow: ellipsis;
  max-width: calc(100% - 1.25rem); // 20px
}
