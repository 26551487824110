@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../../styles/variables.scss";

// NOTE: Emulating bootstrap form select styles
.dropdown {
  :global {
    .btn.show {
      color: $gray-900;
      background-color: $white;
      border: 1px solid $gray-400;
    }
  }

  button {
    display: block;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $gray-900;
    background-color: $white;
    border: 1px solid $gray-400;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;

    &:active,
    &:focus-visible {
      border-color: #a1c6f6 !important;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgb(67 141 237 / 25%);
      background-color: $white !important;
      color: $gray-900 !important;
    }

    &:hover {
      color: $gray-900;
      background-color: $white;
      border: 1px solid $gray-400;
    }

    &::after {
      display: none;
    }

    &:disabled {
      background-color: $gray-200;
      border: 1px solid $gray-400;
      color: $gray-900;
      opacity: 1;
    }
  }

  :global {
    .form-select.is-invalid:hover {
      border-color: $red;
    }
  }

  ul {
    width: 100%;
    max-height: 18.75rem; // 300px
    overflow-x: auto;

    &:focus-visible {
      outline: none;
    }
  }

  .option {
    cursor: pointer;
    white-space: normal;
    word-break: break-word;
  }

  .placeholder {
    color: $grayscale-50;
  }
}

@include media-breakpoint-down(sm) {
  .dropdown {
    ul {
      max-height: 15.625rem; // 250px
    }
  }
}
