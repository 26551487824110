@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../styles/variables.scss";

.container {
  min-height: 100vh;
}

.navHeader {
  padding: 1.5rem;

  .logo {
    margin-bottom: 2.25rem;
    width: 5.6875rem; // 91px
    height: 2.4375rem; // 39px;
  }
}

.navPanel {
  border-right: 1px solid $grayscale-80;
}

@include media-breakpoint-down(lg) {
  .container {
    :global {
      .row {
        width: 100%;
      }
    }
  }

  .navHeader {
    display: none;
  }

  .navPanel {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: $white;
    box-shadow: 0px -0.1875rem 1.25rem rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  .labelContainer {
    flex-direction: column;

    span {
      font-size: 0.6875rem; // 11px
    }

    .icon {
      margin: 0;
    }
  }

  .arrow {
    display: none;
  }

  .navLink {
    justify-content: center;
    padding: 0 !important;
    height: 6.25rem; // 100px
  }

  .navItem {
    width: 25%;

    :global {
      .active {
        box-shadow: inset 0px 0.25rem 0px 0px $brand-red;
        border-right: none;
      }
    }
  }

  .navItem + .navItem {
    border-left: 1px solid $brand-light-grey;
    border-top: none;
  }

  .content {
    height: calc(100vh - 6.25rem);
    overflow-y: auto;
    padding-bottom: 6.25rem;
  }
}

@include media-breakpoint-down(xl) {
  .menuSubtitle {
    font-size: 0.875rem; // 14px
  }
}
