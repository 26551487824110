@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../../styles/variables.scss";

.header {
  font-size: 1rem;
}

.text {
  font-size: 0.875rem; // 14px
  line-height: 1.5rem;
  margin: 0;
}

.longContent {
  overflow-wrap: anywhere;
}

.badge {
  margin-left: 0.25rem;
}

.dataText {
  font-size: 0.875rem; // 14px
  color: $grayscale-40;
  text-align: end;
}

@include media-breakpoint-up(lg) {
  .emailsCol {
    flex-grow: 4 !important;
  }

  .container {
    :global {
      .col {
        margin-bottom: 0;
      }
    }
  }
}
