@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "../../../styles/variables.scss";

.card {
  .button {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem; // 14px
  }
}
