@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../styles/variables.scss";

.subtitle {
  line-height: 1.375rem; // 22px
}

.actionOption {
  border-bottom: 1px solid $brand-light-grey;
  padding: 1.5rem;
}

.optionSubTitle {
  color: $grayscale-40;
}

.deleteAddressButton {
  width: 100%;
  border-radius: 0 !important;
  background: transparent !important;
  border: none !important;
  color: $brand-black !important;
}

.selectedPickup {
  padding-left: 2.5rem; // 40px
  color: $blue;
  font-size: 0.875rem; // 14px
  min-height: 1.3125rem; // 21px
}
