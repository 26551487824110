@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.container {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  content: "";
  background-image: url("../../../assets/icons/BackgroundMobile.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  overflow-x: hidden;
}

@media screen and (max-width: 568px) {
  .container {
    min-height: calc(100vh + 0.625rem); // 10px
  }
}

.logo {
  width: 3.75rem; // 60px
  height: 1.5625rem; // 25px
}

.header {
  padding: 1.375rem 1.5rem;
  width: 100%;
}

@include media-breakpoint-up(md) {
  .header {
    position: absolute;
    padding: 0 2.5rem; // 40px
    top: 0;
    box-shadow: none;
    margin-bottom: 0;
    margin-top: 1.125rem; // 18px
    width: auto;
  }

  .logo {
    width: 5.6875rem; // 91px
    height: 2.4375rem; // 39px
    margin: 0;
  }

  .container {
    background-image: url("../../../assets/icons/OnboardingBackgroundDesktop.svg");
  }

  .content {
    padding: 3.4375rem 0; // 55px
  }

  @include media-breakpoint-up(md) {
    @media (max-height: 800px) {
      .content {
        padding-bottom: 7.5rem; // 120px
      }
    }
  }
}
