@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.container {
  border-radius: 0 10px 10px 10px;
  border: 1px solid #999999;
  overflow: hidden;

  :global {
    .mapboxgl-canvas {
      border-radius: 0 10px 10px 10px;
    }
  }
}

.undoButtonContainer {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 5; // should be on top of active markers
}

.undoButtonContainer:hover {
  background-color: #e4e4e4 !important;
  border-color: #e4e4e4 !important;
}

.mapLoader {
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.6;
  z-index: 10;
  position: absolute;
}

.mapBoxErrorMessage {
  z-index: $zindex-tooltip;
  font-size: 0.875rem; // 14px
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
}

@include media-breakpoint-down(sm) {
  .mapBoxPinLocation {
    font-size: 0.875rem; // 14px
  }
}
