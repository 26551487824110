@import "../../../styles/variables";

.dataText {
  word-wrap: break-word;
  overflow-wrap: anywhere;
}

.deleteButton {
  height: 2.375rem; // 38px
}

.helperText {
  font-size: 0.875rem; // 14px
  color: $grayscale-40;
}
