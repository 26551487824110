@import "../../../styles/variables.scss";

.container {
  max-width: 56.25rem; // 900px
}

.dot {
  width: 0.625rem; // 10px
  height: 0.625rem; // 10px
  border-radius: 50%;
  background-color: #e6e6e6;
}

.dot + .dot {
  margin-left: 0.5rem;
}

// TODO: Clarify background color for active step dot
.dotActive {
  background-color: $brand-red;
}

.dotValid {
  background-color: $green;
}

.nextButtonContainer {
  min-width: 5.1875rem; // 83px
}
