.address {
  span {
    display: block;
    font-weight: 400;
    font-size: 1rem;
    line-height: 125%;
    font-family: "Roboto";
    color: rgba(0, 0, 0, 0.6);
  }
}
