@import "../../styles/variables.scss";

.navPanel {
  border-right: 1px solid $grayscale-80;
}

.helperText {
  font-size: 0.875rem; // 14px
  color: $grayscale-40;
}
