.wrapper {
  width: fit-content;
}

.pinInput {
  width: 2.1875rem !important; // 35px
}

.pinInput + .pinInput {
  margin-left: 0.5rem;
}
