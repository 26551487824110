$white: #fff;
$black: #000;
$blue: #438ded;
$green: #8ac149;
$red: #e5444b;
$orange: #fe9400;
$brand-red: #dc0032;
$brand-black: #414042;
$brand-mid-grey: #808285;
$brand-light-grey: #e6e7e8;
$link-red: #e5444b;
$grayscale-75: #b3b3b3;
$grayscale-40: #666666;
$grayscale-50: #808080;
$grayscale-95: #f2f2f2;
$grayscale-80: #cccccc;
$info-red-5: #fce3dc;
$info-red-6: #f6958d;
$green-008: rgba(138, 193, 73, 0.08);
$green-025: rgba(138, 193, 73, 0.25);
$dialog-warning: rgba(255, 225, 83);
$dialog-warning-015: rgba(255, 225, 83, 0.15);

$zindex-cpp-backdrop: 1500;
$btn-link-color: $brand-black;
$btn-link-hover-color: $link-red;
$alert-link-font-weight: 400;
$container-max-widths: (
  md: 32.5rem,
);
$grid-gutter-width: 1rem;
$form-label-margin-bottom: 0.25rem;
$navbar-brand-padding-y: 1.375rem; // 22px
$navbar-brand-margin-end: 0;
$toast-header-color: rgba($black, 0.85);
$toast-header-border-color: #e6e6e6;
$badge-font-size: 0.75rem; // 12px
$badge-font-weight: 400;
$badge-padding-y: 0.375rem; // 6px
$badge-padding-x: 0.375rem;
$badge-border-radius: 0.875rem; // 14px
$enable-validation-icons: false;
$display-font-sizes: (
  1: 1.25rem,
  2: 1.125rem,
  3: 1rem,
  4: 0.875rem,
  5: 0.75rem,
  6: 0.6875rem,
);
$display-font-weight: normal;
$headings-font-family: "PlutoSans";
$modal-sm: 18.75rem; // 300px
$modal-md: 31.25rem; // 500px
$modal-lg: 50rem; // 800px
$modal-xl: 71.25rem; // 1140px
$offcanvas-horizontal-width: 25rem; // 400px
$toast-max-width: 21.875rem; // 350px
