@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "../../../styles/variables.scss";

.inlineImage {
  display: none;
}

.imageContainer {
  position: relative;
  min-height: 7.8125rem; // 125px
  max-width: 20.625rem; // 330px
  border: 1px solid $brand-light-grey;

  .img {
    min-height: 7.8125rem; // 125px
    max-width: 20.625rem; // 330px
  }
}

.divider {
  border-top: 1px solid $brand-light-grey;
  border-bottom: 1px solid $brand-light-grey;
}

.closedForLunchContainer {
  border-bottom: 1px solid $brand-light-grey;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .inlineImage {
    display: flex;
    justify-content: center;
  }

  .sideImage {
    display: none;
  }

  .divider {
    border-top: 0;
  }
}

@media (min-width: 1400px) and (max-width: 1550px) {
  .sideImage {
    width: 50% !important;
  }
}
