.filterButtonsContainer {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 4; // more than active markers
}

.mapContainer {
  height: 45%;
}

.listContainer {
  height: 55%;
  overflow-y: auto;
  overflow-x: hidden;
}

.list {
  height: auto;
}
