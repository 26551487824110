@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "../../../styles/variables.scss";

.container {
  position: relative;
  width: 1.5rem;

  .checkmark {
    position: absolute;
    top: -0.3125rem; // -5px
    width: 0.625rem; // 10px
    height: 0.625rem; // 10px
    right: 0;
  }

  .label {
    display: none;
  }

  .iconWithLabel {
    width: 1.5rem;
  }
}

.active {
  color: $brand-red;
}

.valid {
  .icon {
    color: $brand-red;
  }
}

.disabled {
  opacity: 0.5;
  color: $brand-black;

  .icon {
    color: $brand-black;
  }
}

@include media-breakpoint-up(md) {
  .container {
    width: 5.125rem; // 82px

    .checkmark {
      top: -0.625rem; // -10px
      width: 1.25rem; // 20px
      height: 1.25rem;
    }

    .label {
      display: inline;
    }

    .iconWithLabel {
      width: 100%;
    }
  }
}
