@import "../../../styles/variables";

.backdrop {
  display: flex;
  width: 100%;
  height: 100%;
  z-index: $zindex-cpp-backdrop;
  top: 0;
  left: 0;
}
