@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "../../../styles/variables.scss";

.preferredPickupCard.card {
  border: 1px solid $brand-black;
  overflow: hidden;
}

.card.selected {
  background: $green-008;
  border: 1px solid $green;
  box-shadow: 0px 0px 0px 4px $green-025;
}

.mapContainer {
  height: 10.375rem; // 166px
}

@include media-breakpoint-up(md) {
  .mapContainer {
    height: auto;
  }
}
