.container {
  width: 5.125rem; // 82px
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    font-size: 0.75rem; // 12px
  }
}

.inline {
  width: 100%;
  flex-direction: row;
  justify-content: start;
}
