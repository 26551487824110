@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.titleContainer {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pickupInfoText {
  font-size: 1.125rem;
}

@include media-breakpoint-up(md) {
  .pickupInfoText {
    font-size: 1rem;
    line-height: 1.1875rem;
  }
}
