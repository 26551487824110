@import "../../../styles/variables.scss";

.card {
  .title {
    font-size: 1rem;
    font-weight: 700;
    display: flex;
    align-items: center;
  }

  .message {
    font-size: 0.875rem; // 0.875rem = 14px
    font-weight: 400;
  }
}
