.backButton {
  align-items: center;
}

.backButtonText {
  margin-top: -0.125rem; // -2px
  margin-left: 0.875rem; // 14px
}

.icon {
  min-width: 0.5rem; // 8px
  height: 0.75rem;
}
