@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.stepContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

@include media-breakpoint-up(md) {
  .step {
    min-height: calc(100vh - 25rem); // 400px
  }
}
