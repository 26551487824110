@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "../../../styles/variables.scss";

@include media-breakpoint-down(lg) {
  .resendButton:hover {
    color: $brand-black !important;
  }
}
