@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "../../../styles/variables.scss";

.image {
  max-height: 9rem; // 144px
  padding: 0;
  border-radius: 0;
  border: none;
}

.dropzone {
  min-height: 11.125rem; // 178px
  border: 1px dashed $grayscale-75;
}

.button {
  border-width: 2px;
}
