@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../../styles/variables.scss";

.pinPointLocation {
  :global {
    .nav-item {
      &:first-child .nav-link {
        border-right: none;
        border-top-right-radius: unset;
      }

      &:last-child .nav-link {
        border-top-left-radius: initial;
        border-left: none;
      }

      .nav-link {
        color: $brand-black;
        border: 1px solid #999999;
        box-sizing: border-box;
        border-bottom: 0;

        &:hover {
          border-color: #999999;
        }
        &.active {
          color: white !important;
          background-color: $brand-red;
          border-color: $brand-red;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .pinPointLocation {
    font-size: 0.875rem; // 14px
  }
}
