/** Step Wizard */
.step-wrapper {
  position: relative;
}

.step {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.active {
  opacity: 1;
  pointer-events: inherit;
  position: relative;
  z-index: 1;
}
