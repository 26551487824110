@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "../../../styles/variables.scss";

.card {
  padding: 0.5rem;
  width: 100%;

  .title {
    font-weight: 500;
    font-size: 1.125rem; // 18px
    line-height: 1.3125rem; // 21px
    font-style: normal;
    margin-bottom: 1.5rem; // 24px
  }

  .icon {
    width: 2.25rem; // 36px
    height: 2.25rem;
    margin-right: 0.5rem;
    color: $red;
  }

  .button {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem; // 14px
  }

  .text {
    span {
      display: block;
      font-weight: 400;
      font-size: 1rem;
      line-height: 125%;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}

.secondary {
  padding-top: 0;
  padding-bottom: 0;

  .cardBody {
    padding: 0;
  }

  .titleContainer {
    background: $grayscale-95;
    padding: 1rem 0.5rem;
    border-top-left-radius: 0.375rem; // 6px
    border-top-right-radius: 0.375rem;
    border-bottom: 1px solid $grayscale-80;
  }

  .title {
    margin-bottom: 0.875rem; // 14px
  }

  .content {
    padding: 0;
  }
}

@include media-breakpoint-up(md) {
  .card {
    width: 35.4375rem; // 567px
    gap: 0.5rem;
  }
}
