@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.container {
  min-height: calc(100vh - 6.25rem); // 100px, minus footer menu height
}

@include media-breakpoint-up(md) {
  .container {
    height: calc(100vh - 6.25rem);
    display: flex;
    flex-direction: column;
  }

  .content {
    max-height: 100%;
    overflow-y: auto;
  }

  .step {
    min-height: calc(100vh - 25rem); // 400px
  }
}

@include media-breakpoint-up(lg) {
  .container {
    height: 100vh;
  }
}

.stepContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
