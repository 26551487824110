@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../styles/variables.scss";

@include media-breakpoint-up(md) {
  .container {
    max-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .content {
    max-height: 100%;
    overflow-y: auto;
  }
}

.header {
  border-bottom: 1px solid $grayscale-80;
}

.navPanel {
  border-right: 1px solid $grayscale-80;
}

.subtitle {
  font-size: 0.875rem; // 14px
  color: $grayscale-40;
}

.addEmailButton {
  padding: 0.875rem 0 !important;

  svg {
    margin-right: 0.6875rem; // 11px
  }
}

.helperText {
  font-size: 0.875rem; // 14px
  color: $grayscale-40;
}

@include media-breakpoint-down(md) {
  .navPanel {
    padding: 0 !important;
  }
}

.deleteProfile {
  margin: 2.5rem 0 0.625rem;
}
