@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "../../../styles/variables.scss";

.container {
  max-height: calc(100vh - 12.5rem); // 200px
  overflow-y: scroll;
  overflow-x: hidden;
}

.partnerLogoContainer {
  height: 12.875rem; // 206px
}

.partnerLogo {
  min-height: 12.875rem; // 206px
  border: 1px solid $brand-light-grey;
  object-fit: cover;
}

.divider {
  border-bottom: 1px solid $border-color-translucent;
}
