@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../styles/variables.scss";

@include media-breakpoint-up(lg) {
  .container {
    height: 100vh;
  }
}

@include media-breakpoint-down(lg) {
  .content {
    max-height: calc(
      100vh - 12.8125rem
    ); // 205px, minus header and footer height
  }
}
