@import "variables";

/* import the necessary Bootstrap files */
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons";

// white background color for all outline buttons
@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    background-color: #{$white};
  }
}

// success, primary and danger btn styles changes due to green, blue and red colors changes
$changed-main-color-variants: ("primary", "success", "danger");

@each $variant in $changed-main-color-variants {
  .btn-#{$variant} {
    --#{$prefix}btn-color: #{$white};
    --#{$prefix}btn-hover-color: #{$white};
    --#{$prefix}btn-active-color: #{$white};
    --#{$prefix}btn-disabled-color: #{$white};
  }

  .btn-outline-#{$variant} {
    --#{$prefix}btn-hover-color: #{$white};
    --#{$prefix}btn-active-color: #{$white};
  }
}

// alert customization
.alert {
  --#{$prefix}alert-color: #414042;
}

// alert-loops
@each $color, $value in $theme-colors {
  .alert-#{$color} {
    --#{$prefix}alert-border-color: #{$value};
  }
}

// customize toast component

// toast-bg-loops
@each $color, $value in $theme-colors {
  .toast.bg-#{$color} {
    --#{$prefix}toast-border-color: #{$value};
    .toast-header {
      .btn-close {
        opacity: 1;
      }
    }
    .toast-body {
      @include border-bottom-radius(var(--#{$prefix}toast-border-radius));
      background-color: var(--#{$prefix}toast-bg);
    }
  }
}

.badge.bg-danger {
  // NOTE: bootstrap sets background-color for .bg-danger with !important
  background-color: $info-red-5 !important;
  color: $red;
}

// align error text to the right
.invalid-feedback {
  text-align: end;
}

.btn-danger {
  --#{$prefix}btn-disabled-bg: #{$info-red-5};
  --#{$prefix}btn-disabled-border-color: transparent;
  --#{$prefix}btn-disabled-color: #{$info-red-6};
}

@include media-breakpoint-down(lg) {
  .btn-outline-danger:hover {
    background-color: $white !important;
    color: $red !important;
  }
}
