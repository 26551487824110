@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../styles/variables.scss";

@include media-breakpoint-up(md) {
  .container {
    max-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .content {
    max-height: 100%;
    overflow-y: auto;
  }
}

.header {
  border-bottom: 1px solid $grayscale-80;
  color: $brand-black;
}

.addressListContainer {
  > :last-child {
    border-bottom: none;
  }
}

.navPanel {
  border-right: 1px solid $grayscale-80;
}
