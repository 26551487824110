@import "../../../styles/variables.scss";

.messageContainer {
  font-size: 0.875rem; // 14px
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.5rem;
}

.message {
  padding: 1rem;
  background-image: linear-gradient(
    0deg,
    rgba(229, 68, 75, 0.15),
    rgba(229, 68, 75, 0.15)
  );
  background-color: $white;
  border: 1px solid $red;
  border-radius: 0.375rem; // 6px
  font-size: 1rem;
}
