@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "../../../styles/variables.scss";

.card {
  padding: 1rem;
  width: 100%;

  .checkmark {
    display: none;
  }

  .iconWithLabel {
    width: auto;
    flex-direction: row;
  }

  .button {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem; // 14px
  }

  .label {
    font-size: 1rem;
    margin-left: 0.375rem; // 6px
  }

  .icon {
    width: 2rem;
    height: 2rem;
    color: $brand-red;
  }
}

.card.selected {
  background: $green-008;
  border: 2px solid $green;
  box-shadow: 0px 0px 0px 4px $green-025;
  position: relative;

  .button {
    background-color: $green;
    color: #ffffff;
    border: none;

    &:hover,
    &:active,
    &:focus-visible {
      background-color: $green;
      box-shadow: none;
    }
  }

  // NOTE: Overrides weird bootstrap styling rule - :not(.btn-check) + .btn:active
  .iconWithLabel + .button:active {
    background-color: $green;
    border-color: $green;
  }
}

.card.inline {
  .icon {
    color: $brand-black;
  }
}

@include media-breakpoint-up(md) {
  .card:not(.inline) {
    width: 100%;
    height: 13.1875rem; // 211px
    gap: 1rem;

    .iconWithLabel {
      gap: 1rem;
      flex-direction: column;
    }

    .icon {
      width: 4rem;
      height: 4rem;
    }

    .label {
      margin-left: 0;
    }
  }

  .selected {
    .checkmark {
      display: block;
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .card {
    width: 12.5rem; // 200px
  }
}
