@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "../../../styles/variables.scss";

.title {
  font-family: "PlutoSans";
  font-weight: 500;
}

.icon {
  height: 2.25rem; // 36px
  width: 2.25rem;
}

@include media-breakpoint-up(md) {
  .icon {
    height: 3.9375rem; // 63px
    width: 3.9375rem;
  }
}
