.text {
  span {
    font-family: "Roboto";
    font-weight: 400;
    font-size: 1.125rem; // 18px
    line-height: 125%;
    font-style: normal;
  }
}

.image {
  width: 100%;
}
