@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../../styles/variables.scss";

.longContent {
  overflow-wrap: anywhere;
}

.ellipsis {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container {
  width: auto;
  height: 100% !important;

  .header {
    font-size: 1rem;
  }
}

.dataText {
  font-size: 0.875rem; // 14px
  color: $grayscale-40;
  text-align: end;
}

.dataRow + .dataRow {
  border-top: 1px solid $grayscale-80;
}

.dataLabel {
  font-size: 0.875rem;
}

.iconWithLabel {
  .dataLabel {
    font-size: 0.875rem;
  }

  .icon {
    color: $brand-red;
    margin-right: 0.5rem;
  }
}

.newAddressButton {
  border: 1px solid $grayscale-80 !important;
  font-size: 1.25rem !important; // 20px
  min-height: 4.75rem; // 76px
}
